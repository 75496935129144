import React, { useState, useEffect } from "react"
import cn from "classnames"

import VerifyCode from "@ecom/ui/components/VerifyCode"
import Button from "@material-ui/core/Button"

import { navigate } from "gatsby"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { sendRequest } from "../../../utils/sendRequest"
import { FormPageContainer } from "../FormPageContainer"

import * as styles from "./verifySMS.module.scss"

type HelperTextProps = {
  time: number
  getNewCode: React.MouseEventHandler<HTMLSpanElement> | undefined
}

export type VerifySMSProps = {
  id: string
  data: any
}

const TIMER_START = 25
function sendSms(data: any) {
  return sendRequest(`${process.env.GATSBY_API_URL}/v2/app/promo`, { ...data })
}

function HelperText({ time, getNewCode }: HelperTextProps) {
  if (time === 0) {
    return (
      <span aria-hidden="true" className={cn(styles.helperText, styles.retry)} onClick={getNewCode}>
        Отправить код повторно
      </span>
    )
  }

  return (
    <span className={styles.helperText}>
      Отправить код повторно через <b>{time} сек</b>
    </span>
  )
}

export function VerifySMS({ id, data }: VerifySMSProps) {
  const [token, setToken] = useState("")
  const [errorMsg, setErrorMsg] = useState("Неверный код")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    if (id) {
      setToken(id)
    }
  }, [id])

  useEffect(() => {
    if (time > 0) {
      const timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
      return () => clearTimeout(timerID)
    }
    return () => {}
  })

  function submitCode(smsCode: string) {
    sendRequest(
      `${process.env.GATSBY_API_URL}/v2/app/promo/${token}`,
      { code: Number(smsCode) },
      "put"
    )
      .then((res) => {
        setIsSending(true)
        const { result } = res
        if (result === "success") {
          pushToDataLayer({ event: "sms_verified", result: "success" })
          navigate("/inmyroom/success")
        }
        if (result === "error") {
          pushToDataLayer({ event: "sms_verified", result: "error" })
          navigate("/inmyroom/error")
        }
      })
      .catch(() => {
        pushToDataLayer({ event: "sms_verified", result: "error" })
        setIsError(true)
      })
  }

  function getNewCode() {
    setCode("")
    setIsError(false)
    sendSms(data)
      .then((res) => {
        setToken(res.id)
        setTime(TIMER_START)
      })
      .catch(() => {
        setIsError(true)
        setErrorMsg("Превышен лимит запросов")
      })
  }

  function handleChange(e: { target: { value: any } }) {
    const {
      target: { value },
    } = e

    setCode(value)

    if (isError) {
      setIsError(false)
    }
  }

  function checkCode() {
    if (code.length === 4) {
      submitCode(code)
    } else {
      setIsError(true)
    }
  }

  return (
    <FormPageContainer>
      <h1 className="head">Введите код из СМС</h1>
      <p className="description">
        На Ваш номер выслан код
        <br className="d-sm-none" /> для проверки телефона:
      </p>
      <VerifyCode
        onChange={handleChange}
        value={code}
        error={isError}
        errorMsg={errorMsg}
        helperText={<HelperText time={time} getNewCode={getNewCode} />}
        classes={{
          verifyCodeInput: styles.input,
          errorTextRoot: styles.errText,
        }}
      />
      <Button className="submitBtn" onClick={checkCode} disabled={isSending}>
        Продолжить
      </Button>
    </FormPageContainer>
  )
}
