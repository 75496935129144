function checkStatus(response: any) {
  return response
    .json()
    .then((result: any) => {
      if (response.ok) {
        return Promise.resolve(result)
      }

      return Promise.reject(result)
    })
    .catch((err: any) => Promise.reject(err))
}

export function sendRequest(url: string, data: any, method = "post") {
  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(checkStatus)
}
