import React, { useEffect } from "react"
import { navigate } from "gatsby"
import type { PageProps } from "gatsby"
import { Layout } from "../../components/Layout"
import { VerifySMS, VerifySMSProps } from "../../components/inmyroom/VerifySMS"

const seoData = {
  title: "Акция Кубок Халвы",
  description: "Регистрация в акции кубок Халвы Совкомбанк",
}

export default function SmsPage({ location }: PageProps<object, object, VerifySMSProps>) {
  const { id, data } = location?.state || {}

  useEffect(() => {
    if (!id) {
      navigate("/inmyroom/")
    }
  }, [id])

  return (
    <Layout seoData={seoData} hasCustomFooter>
      <VerifySMS id={id} data={data} />
    </Layout>
  )
}
