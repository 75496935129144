import React, { ReactNode } from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./formPageContainer.module.scss"

export type FormPageContainerProps = {
  children: ReactNode
}

export function FormPageContainer({ children }: FormPageContainerProps) {
  return (
    <section className={styles.root}>
      <Container>{children}</Container>
    </section>
  )
}
